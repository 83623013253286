import ToastVariant from '@/model/enums/toast/toast-variant';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ToastMixin extends Vue {
    showToast(message: string, title: string, variant: ToastVariant): void {
        this.$bvToast.toast(message, {
            title,
            variant,
            autoHideDelay: 5000,
            appendToast: false,
        });
    }
}
