var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-overlay', {
    staticClass: "d-flex flex-column",
    attrs: {
      "show": _vm.loading || !_vm.initialized
    }
  }, [_c('editor', {
    attrs: {
      "api-key": _vm.editorKey,
      "init": {
        height: 500,
        menu: {
          file: {
            title: 'File',
            items: 'newdocument restoredraft | preview | print '
          },
          edit: {
            title: 'Edit',
            items: 'undo redo | cut copy paste | selectall | searchreplace'
          },
          view: {
            title: 'View',
            items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen'
          },
          insert: {
            title: 'Insert',
            items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
          },
          format: {
            title: 'Format',
            items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'
          },
          tools: {
            title: 'Tools',
            items: 'spellchecker spellcheckerlanguage | code wordcount'
          },
          table: {
            title: 'Table',
            items: 'inserttable | cell row column | tableprops deletetable'
          }
        },
        plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code wordcount'],
        toolbar: 'undo redo | formatselect fontsizeselect fontselect | bold italic forecolor backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat'
      }
    },
    on: {
      "onSelectionChange": _vm.loaded
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  }), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('how-to.button.update')) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }